import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../assets/logo.png";
import { LinkList } from "./LinkList";
import "./footer.css";

export const Footer = () => {
  const Links = [
    {
      label: "¿Que es el IELTS?",
      link: "https://ielts.mx/que-es-ielts/",
    },
    {
      label: "¿Como prepararse para el examen?",
      link: "https://ielts.mx/examen-ielts/",
    },
    {
      label: "Noticias IELTS",
      link: "https://ielts.mx/noticias/",
    },
    {
      label: "Solicita tu visa del reino unido",
      link: "https://ielts.mx/ukvi-ielts/",
    },
    {
      label: "Formacion de profesores de ingles",
      link: "https://universityih.com/cambridge-celta/",
    },
  ];

  const Links2 = [
    {
      label: "Cursos de preparación",
      link: "https://ielts.mx/cursos-ielts/",
    },
    {
      label: "Conoce studylelts",
      link: "https://ielts.mx/nuestros-profesores/",
    },
    {
      label: "¿Donde realizar el examen IELTS",
      link: "https://ielts.mx/sedes-ielts-mexico/",
    },
    {
      label: "Registro para el examen",
      link: "https://ielts.mx/inscripciones-ielts/",
    },
    {
      label: "Formacion de profesores de español",
      link: "https://universityih.com/dieele/",
    },
  ];

  return (
    <>
      <div id="footer-pt1">
        <Container>
          <Row>
            <Col md={4}>
              <Image src={logo} rounded width={"75%"} />
            </Col>
            <Col md={4}>
              <LinkList links={Links}></LinkList>
            </Col>
            <Col md={4}>
              <LinkList links={Links2}></LinkList>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="footer-pt2">
        <Container>
          <Row className="footer-pt2-text">
            <Col md={3}>
              <span>2021 © Copyright - IELTS México</span>
            </Col>
            <Col md={1}></Col>
            <Col md={8}>
              <span id="aviso"><a href="https://ielts.mx/aviso-de-privacidad/">Aviso de Privacidad</a></span>
              <span className="terminos"><a href="https://ielts.mx/terminos-y-condiciones/"> Términos y Condiciones </a></span>
              <span className="terminos"> <a href="https://ielts.mx/wp-content/uploads/2023/07/IELTS_Calendario_2024-2.pdf">Términos y condiciones generales para la contratación de Exámenes Internacionales</a>
              </span>
            </Col>
            <Col>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


