import { useState, useEffect } from "react";
import axios from "axios";
import { BsPersonFill, BsSend } from "react-icons/bs";
import logo from "./../assets/logo.png";
import { Image, Stack, Badge, Form, Button } from "react-bootstrap";

const ParseHiperLink = ({ msg }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const url = msg.match(urlRegex);

  if (!url) {
    return <>{msg}</>;
  }

  let msg_split = msg.replace(url, "");
  return (
    <>
      {msg_split}{" "}
      <a
        href={url}
        key={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "blue" }}
      >
        {url}
      </a>
    </>
  );
};
export function ChatBot() {
  const [chat, setChat] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [botTyping, setbotTyping] = useState(false);
  const [senderId, setSenderId] = useState("");

  useEffect(() => {
    rasaAPI(getTime(), "hola");
  }, []);

  useEffect(() => {
    const objDiv = document.getElementById("messageArea");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [chat]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const name = senderId;
    const request_temp = {
      sender_id: name,
      msg: inputMessage,
    };

    if (inputMessage !== "") {
      setChat((chat) => [...chat, request_temp]);
      setbotTyping(true);
      setInputMessage("");
      rasaAPI(name, inputMessage);
    } else {
      window.alert("Please enter valid message");
    }
  };

  const rasaAPI = async function handleClick(name, msg) {
    try {
      const url = "https://chatbot.ih.web.ixulabs.com/webhooks/rest/webhook";
      const response = await axios.post(
        url,
        {
          sender: name,
          message: msg,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        response.data.forEach((element) => {
          //          const temp = response.data[0];
          const recipient_id = element["recipient_id"];
          const recipient_msg = element["text"];

          const response_temp = {
            sender: "bot",
            recipient_id: recipient_id,
            msg: recipient_msg,
          };
          setbotTyping(false);

          setChat((chat) => [...chat, response_temp]);
        });
      }
    } catch (error) {
      // Manejar el error en caso de que ocurra
      console.error("Error al llamar a la API:", error);
    }
  };

  const styleBody = {
    height: "40vh",
    overflowX: "hidden",
  };

  const getTime = () => {
    const today = new Date();
    let now = today.toLocaleString();
    setSenderId(now);
    return now;
  };
  return (
    <div className="">
      <h1 style={{ marginBottom: "0px" }}>AI Assistant</h1>
      {botTyping ? <h6>Bot Typing....</h6> : <h6>.</h6>}
      <div className="cardBody" id="messageArea" style={styleBody}>
        <div className="row msgarea">
          {chat.map((user, key) => (
            <div key={key}>
              {user.sender === "bot" ? (
                <Stack
                  direction="horizontal"
                  style={{
                    alignItems: "flex-start",
                    alignContent: "center",
                  }}
                  gap={3}
                >
                  <div className="p-0">
                    <Badge pill bg="light">
                      <Image
                        src={logo}
                        roundedCircle
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Badge>
                  </div>
                  <div style={{ whiteSpace: "pre-line", overflow: "scroll" }}>
                    {" "}
                    <ParseHiperLink msg={user.msg} />{" "}
                  </div>
                </Stack>
              ) : (
                <Stack
                  direction="horizontal"
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    backgroundColor: "#ededed",
                  }}
                  gap={3}
                >
                  <div
                    style={{
                      whiteSpace: "pre-line",
                      textAlign: "right",
                    }}
                  >
                    {user.msg}{" "}
                  </div>
                  <div className="p-2">
                    <Badge pill bg="danger" style={{ padding: "5px " }}>
                      <BsPersonFill />
                    </Badge>
                  </div>
                </Stack>
              )}{" "}
            </div>
          ))}{" "}
        </div>
      </div>

      <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
        <Stack direction="horizontal">
          <Form.Control
            type="text"
            placeholder="Escribe aqui..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <Button type="submit" variant="outline-primary">
            <BsSend />
          </Button>
        </Stack>
      </form>
    </div>
  );
}
