import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

import logo from "../assets/logo.png";
import './navbar.css'


export function NavBar() {
  const links = [
    {
      label: "Inicio",
      link: "https://ielts.mx/",
    },
    {
      label: "Preparate",
      link: "https://ielts.mx/",
    },
    {
      label: "Certificate",
      link: "https://ielts.mx/",
    },
    {
      label: "Paquete examen gratis",
      link: "https://ielts.mx/paquetes-ielts/",
    },
    {
      label: "Sedes",
      link: "https://ielts.mx/sedes-ielts-mexico/",
    },
  ];

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <Image src={logo} rounded width={"150px"} className="d-none d-lg-block" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {links.map((link, index) => (
              <Nav.Link key={index} href={link.link} className="nav-link">{link.label}</Nav.Link>
            ))}

            <Button variant="danger" href="https://ielts.mx/inscripciones-ielts/" className=".nav-button">Registro Examen</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
