// or less ideally
import {Container, Row, Col} from "react-bootstrap";
import "./chatbot.css";
import logo from "./../assets/logo.png";
import {ChatBot} from "./Chatbot";

export const ChatBotContainer = () => {
  return (
    <>
      <div id="container">
        <Container>
          <Row className="justify-content-center">
            <Col xs={8} md={3}>
              {" "}
              <img id="logo"
                src={logo}
                alt="Logo"
                className="w-100"/>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8}>
              <ChatBot/>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  );
};
