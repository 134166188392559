import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ChatBotContainer } from './components/chatbot/ChatBotContainer';
import { NavBar } from './components/navbar/Navbar';
import {Footer} from './components/footer/Footer';

function App() {
  return (
    <div>
      <NavBar/>
      <ChatBotContainer/>
      <Footer></Footer>
    </div>
  );
}

export default App;
