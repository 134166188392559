import './linklist.css'

export const LinkList = ({ links }) => {
  return (
    <>
      {links.map((element, index) => (
        <div key={index}>
          <a href={element.link}>{element.label}</a>
        </div>
      ))}
    </>
  );
};
